
import { Vue, Options } from 'vue-class-component'
import axios from '@/plugins/axios'

interface Result {
  list: any[]
  currentPage: number
  lastDate: Date
  total: number
  data: any
  message: string
  success: boolean
  connections: []
}

@Options({
  components: {}
})
export default class UserPage extends Vue {
  name = 'Account Billing'
  currentPage = 1
  totaRows = 0
  perPage = 20
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  listData: any = []
  dataItem = {}
  dialogAccountBillingDetail = false
  dialogEditPrice = false
  listHistoryInvoice = []
  listAccountEmail: any = []
  listAccountName: any = []
  companyData: any = []
  condition: any = {}
  formData: any = {}
  activeTabs = 'account'
  refForm = ''
  subscriptionTimeMap: any = {
    1: 'Minutely',
    60: 'Hourly',
    1440: 'Daily',
    10080: 'Weekly',
    43200: 'Monthly',
    525600: 'Yearly'
  }
  rules = {
    price: [
      {
        required: true,
        message: 'Please input price',
        trigger: 'blur'
      }
    ]
  }

  async handleViewHistoryInvoice(row: any) {
    this.listHistoryInvoice = row.invoices
    this.dialogAccountBillingDetail = true
  }
  async handleSave() {
    let dataUpdate: any = {}
    dataUpdate._id = this.formData.id
    dataUpdate.keepOnline = this.formData.keepOnline
    dataUpdate.isSuspend = this.formData.isSuspend
    let res: Result
    res = await axios.put('admin/account-billing', dataUpdate, {
      headers: this.headers
    })

    if (res) {
      this.$message.success(res.message)
      this.dialogAccountBillingDetail = false
      this.getList(this.currentPage)
    }
  }
  async handleChangePrice(row: any) {
    this.formData = row
    this.formData._id = row.accountId
    this.formData.isExpireDayChanged = false
    this.formData.isPriceChanged = false
    this.formData.expiryDate = row.expiryDate
    this.formData.connectionId = row.connectionId
    this.dialogEditPrice = true
  }
  async handleSavePrice() {
    this.refForm = 'formUpdate'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    await this.$store.dispatch('setLoading', true, { root: true })

    let res: Result
    res = await axios.put('admin/account-billing/update-price', this.formData, {
      headers: this.headers
    })
    if (res.success) {
      this.$message.success(res.message)
      this.dialogEditPrice = false
    } else {
      this.$message.error(res.message)
      this.dialogEditPrice = false
    }
    await this.getList(this.currentPage)
    await this.getAll()
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async created() {
    await this.getList(1)
    await this.getAll()
  }

  async getAll() {
    let res = await axios.get('admin/account/get-all', {
      headers: this.headers
    })
    if (res && res.data) {
      this.companyData = res.data
    }
  }
  async getList(page = 1) {
    let res: Result
    await this.$store.dispatch('setLoading', true, { root: true })
    res = await axios.get('admin/account-billing/list', {
      headers: this.headers,
      params: {
        page: page,
        limit: this.perPage,
        email: this.condition.email,
        name: this.condition.name
      }
    })

    if (res) {
      this.currentPage = res.currentPage
      this.totaRows = res.total
      this.listData = res.list
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  handleCurrentChange(index = 1) {
    this.currentPage = index
    this.getList(index)
  }
  handleSizeChange(val: number) {
    this.currentPage = 1
    this.perPage = val
    this.getList(this.currentPage)
  }
  resultQuery() {
    this.getList(this.currentPage)
  }
  clearQuery() {
    this.condition = {}
    this.getList(this.currentPage)
  }
}
